import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    console.log("hi ad form!");
  }

  deleteImage(e) {
    e.stopPropagation();
    e.preventDefault();
    console.log("now delete!");
  }
}
